import { GetterTree } from 'vuex';
import { InvoiceState } from './types';
import { RootState } from '../../types';
import { InvoiceSearchData } from '@/shared/model/smallPayloadModels/invoiceSearchData';

export const getters: GetterTree<InvoiceState, RootState> = {
  ['getInvoices']: (state) => state.invoices,
  ['getInvoiceItems']: (state) => state.invoices.items,
  ['getIsUseArchiveInvoices']: (state) => state.isUseArchiveInvoices,

  ['getInvoicesIsLoading']: (state) => state.invoices?.isLoading,
  ['getInvoicesTotal']: (state) => state.invoices?.total,
  ['getInvoicesSearchParams']: (state) => state.invoices?.searchParams,
  ['getInvoiceExcelIsLoading']: (state) => state.invoiceExcelIsLoading,
  ['getInvoicePdfIsLoading']: (state) => state.invoicePdfIsLoading,

  ['getInvoiceMinMaxBruttoNetto']: (state) => state.invoiceMinMaxBruttoNetto,
  ['getInvoiceDocumentTypes']: (state) => state.invoiceDocumentTypes,
  ['getInvoiceMembers']: (state) => state.invoiceMembers,
  ['getInvoiceSuppliers']: (state) => state.invoiceSuppliers,
  ['getInvoiceSearchData']: (state) => state.invoiceSearchData,
  ['getInitialLocalStorageInvoiceSearchData']: (state) => state.initialLocalStorageInvoiceSearchData,
  ['isInvoiceSearchDataInitLocalStorageEmpty']: (state) => {
    const localStorageInvoiceSearchData = state.initialLocalStorageInvoiceSearchData;
    if (!localStorageInvoiceSearchData) return true;

    // TODO (GSP-046): extract duplicate logic in helper class
    const searchData: InvoiceSearchData = JSON.parse(localStorageInvoiceSearchData);
    let hasDate = !!searchData.datum || !!searchData.dueDate || !!searchData.endOfWeekDate;
    let hasDocType = searchData.documentType?.length > 0;
    let hasMembers = searchData.receiverNumbers?.length > 0;
    let hasSupplier = searchData.senderNumbers?.length > 0;
    let hasBrutto = !!searchData.bruttoNetto?.bruttoMin || !!searchData.bruttoNetto?.bruttoMax;
    let hasNetto = !!searchData.bruttoNetto?.nettoMin || !!searchData.bruttoNetto?.nettoMax;

    let isEmpty = !hasDate && !hasSupplier && !hasMembers && !hasDocType && !hasBrutto && !hasNetto;
    return isEmpty;
  },
  ['isInvoiceSearchDataEmpty']: (state) => {
    const localStorageInvoiceSearchData = state.initialLocalStorageInvoiceSearchData;
    if (!localStorageInvoiceSearchData || !state.invoiceSearchData) return true;

    // TODO (GSP-046): extract duplicate logic in helper class
    const searchData: InvoiceSearchData = state.invoiceSearchData; //JSON.parse(localStorageInvoiceSearchData);
    let hasDate = !!searchData.datum || !!searchData.dueDate || !!searchData.endOfWeekDate;
    let hasDocType = searchData.documentType?.length > 0;
    let hasMembers = searchData.receiverNumbers?.length > 0;
    let hasSupplier = searchData.senderNumbers?.length > 0;
    let hasBrutto = !!searchData.bruttoNetto?.bruttoMin || !!searchData.bruttoNetto?.bruttoMax;
    let hasNetto = !!searchData.bruttoNetto?.nettoMin || !!searchData.bruttoNetto?.nettoMax;

    let isEmpty = !hasDate && !hasSupplier && !hasMembers && !hasDocType && !hasBrutto && !hasNetto;
    return isEmpty;
  },
};
