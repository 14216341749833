import { ROUTES } from '@/router/routesEnum';
import axios from 'axios';
import { msalInstance } from 'vue-msal-browser';
import router from '../../router';
import store from '../store';
import { loginApiRequest } from '../utils/authConfig';
import LSService from './LocalStorageService';
const lsService = LSService.getService();

export const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API_URL,
});

export enum URLS {
  authenticate = '/api/Users/login2',
  account = '/api/Users',

  changeVoucherOdata = '/odata/changeVouchers',
  changeVoucher = '/api/changeVouchers',
  changeVoucherDocumentOdata = '/odata/changeVoucherDocuments',
  changeVoucherDocument = '/api/changeVoucherDocuments',
  changeVoucherNoticeOdata = '/odata/changeVoucherNotices',
  changeVoucherNotice = '/api/changeVoucherNotices',

  invoiceOdata = '/odata/invoices',
  invoice = '/api/invoices',
  invoiceArchiveOdata = '/odata/invoiceArchives',
  invoiceArchive = '/api/invoiceArchives',

  wochenabschluesseOdata = '/odata/wochenabschluesse',
  wochenabschluesse = '/api/wochenabschluesse',

  saveListOdata = '/odata/saveLists',
  saveList = '/api/saveLists',

  usersOdata = 'odata/users',
  users = 'api/users',
  userDataOdata = '/odata/userData',
  userData = '/api/userData',
  version = 'api/version', // for test

  zrDocumentOdata = '/odata/zrDocuments',
  zrDocument = '/api/zrDocuments',
  zrNewsOdata = '/odata/zrNews',
  zrNews = '/api/zrNews',

  msGraphUsers = 'https://graph.microsoft.com/v1.0/users',
}

instance.interceptors.request.use(
  async (config: any) => {
    try {
      console.log('config :>> ', config);
      let account = msalInstance!.getAllAccounts()[0]; //.getAllAccounts()[0];

      if (!account) {
        setTimeout( async () => {
          account=msalInstance!.getAllAccounts()[0];
          return await setupConfigWithToken(account, config);
        }, 2000);
        // return config;
      } else {
        return await setupConfigWithToken(account, config);
      }
    } catch (error) {
      // https://github.com/svrcekmichal/redux-axios-middleware/issues/83
      console.error('backend index Error, config URLll:>> ', config?.url);
      console.error(error);
    }
  },
  (error: any) => {
    // store.dispatch(`${URLS.users}/logout`);
    // router.push({
    //   name: ROUTES.login,
    //   params: { checkLogin: 'false' },
    // });
  }
);

async function setupConfigWithToken(account:any, config: any) {
  const msalResponse = await msalInstance?.acquireTokenSilent({
    ...loginApiRequest,
    account: account,
  });
  const tokenMsGraphApi = await msalInstance!.acquireTokenSilent({
    scopes: ['https://graph.microsoft.com/User.Read'],
    account: account,
  });
  // const token = lsService.getAccessToken(); // don't need it for AZURE_AD // TODO: find a way to distinguish between Azure and usual login if needed or remove usual `login/pass` logic
  if (msalResponse && msalResponse.accessToken) {
    config.headers['Authorization'] = 'Bearer ' + msalResponse!.accessToken;
  }
  if (config.url.includes('graph.microsoft.com/v1.0')) {
    config.headers['Authorization'] = 'Bearer ' + tokenMsGraphApi.accessToken;
    config.headers['ConsistencyLevel'] = 'eventual'; // needed for OData `$search` query operator
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
}



instance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    const originalRequest = error.config;
    const refreshToken = lsService.getRefreshToken();
    if (error.response?.status === 401 && !originalRequest._retry && refreshToken) {
      originalRequest._retry = true;

      const token = lsService.getAccessToken();
      return axios
        .post(
          `${URLS.users}/refreshtoken`,
          {
            refreshToken: refreshToken,
            accessToken: token,
          },
          {
            headers: {
              'Content-Type': 'application/json', // TODO: test if need this header (backend was set up to use JSON) + the same for `checkPasswordReset` action
            },
          }
        )
        .then((res: any) => {
          if (res.status === 201 || res.status == 200) {
            lsService.setToken(res.data);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + lsService.getAccessToken();
            return axios(originalRequest);
          }

          return Promise.reject(error);
        });
    } else if (error.response?.status === 401) {
      // NO refresh Token (not checked `Remember me` on Login) and JWT token expired

      store.commit('auth/resetAccount', undefined); //  need this reset to make `isLoggedIn` getter FALSE, otherwise we will see left drawer menu with icons and Username in top left corner
      // router.push({
      //   name: ROUTES.login,
      //   params: { checkLogin: 'false' },
      // });
      // tried to show `'Der Token ist abgelaufen. Bitte erneut anmelden',` snackbar but it always overriden by `"Laden der Daten fehlgeschlagen` red snackbar from `action.ts`
    } else {
      console.error('error backend/index.ts :>> ', error);
      if (error.toString().includes('BrowserAuthError')) {
        return;
      }
    }
    throw error;
  }
);
