import invoiceSearchData from '@/shared/model/smallPayloadModels/invoiceSearchData';
import { InvoiceState } from './types';

export const state = (): InvoiceState => initialState();

export const initialState = (): InvoiceState => ({
  isUseArchiveInvoices: false,
  
  invoiceSearchData: invoiceSearchData.defaultData(),
  initialLocalStorageInvoiceSearchData: undefined,
  invoicePdfIsLoading: false,
  invoiceExcelIsLoading: false,
  invoiceMinMaxBruttoNetto: undefined,
  invoiceDocumentTypes: [],
  invoiceMembers: [],
  invoiceSuppliers: [],
  invoices: {
    items: [],
    isLoading: undefined,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['externe_belegdatum'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [''],
      andClauseFieldsIds: [],
    },
  },
});
